import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TiendaService {


  constructor(private http: HttpClient) { }

  obtenerHttpOptions() {
    return {
      'headers': new HttpHeaders({
        'Content-Type':  'application/json'
        //'Content-Type':  'text/plain'
      })
    }
  }

  listaTiendas() {
     return this.http
      .get(`${environment.apiUrl}Tienda/tiendas`,
      this.obtenerHttpOptions())
}

}
