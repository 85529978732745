import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TiendaComponent } from './tienda/tienda.component';
import { ProductoComponent } from './producto/producto.component';
import { CarritoComponent } from './carrito/carrito.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { DetalleProductoComponent } from './detalle-producto/detalle-producto.component';
import { BorrarComponent } from './borrar/borrar.component';
import { ResultadoBusquedaComponent } from './resultado-busqueda/resultado-busqueda.component';
import { OfertaComponent } from './oferta/oferta.component';

const routes: Routes = [
    {path: '', component: TiendaComponent},
    {path: 'productos', pathMatch: 'full', component: ProductoComponent},
    {path: 'ofertas', pathMatch: 'full', component: OfertaComponent},
    {path: 'busqueda', pathMatch: 'full', component: ResultadoBusquedaComponent},
    {path: 'carrito', pathMatch: 'full', component: CarritoComponent},
    {path: 'checkout', pathMatch: 'full', component: CheckoutComponent},
    {path: 'borrar', pathMatch: 'full', component: BorrarComponent},
    {path: 'producto/:id', component: DetalleProductoComponent},
    {path: 'producto/:id/:color', component: DetalleProductoComponent},
    {path: ':id', component: ProductoComponent},
    {path: '**', pathMatch: 'full', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
