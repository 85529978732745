import { Component, OnInit, Input } from '@angular/core';
import { ProductoService } from '../services/producto.service';
import { Router } from '@angular/router';
import { Producto } from '../models/producto.model';
import { Observable } from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';

@Component({
    selector: 'app-buscador',
    templateUrl: './buscador.component.html',
    styleUrls: ['./buscador.component.css']
})
export class BuscadorComponent implements OnInit {

    listadoProductosSearch: Producto[];
    @Input() totalCarrito: any = 0;
    public model: any;

    constructor(public servicio: ProductoService, private router: Router) { }

    ngOnInit() {

        const carritoData = localStorage.getItem('currentCarrito');

        if (carritoData != null) {
            const carrito = JSON.parse(carritoData);
            this.totalCarrito = carrito.productos.length;
        } else {
            this.totalCarrito = 0;
        }

        const tiendaData = localStorage.getItem('currentStore');

        if (tiendaData != null) {
            const tienda = JSON.parse(tiendaData);
            this.obtenerProducto(tienda.tienda_id);
        } else {
            this.router.navigate(['']);
        }
    }

    obtenerProducto(tienda_id: any) {
        this.servicio
            .listaProducto(tienda_id)
            .subscribe((data: any) => {
                if (data != null) {
                    this.listadoProductosSearch = data;
                } else {
                    console.log('NO DATOS');
                }
            }, error => {
                console.error('ERROR EN SERVICIOS PRODUCTOS');
            });
    }

    onKey(event: any) {
        if (this.model != null && this.model !== undefined) {
            if (typeof this.model === 'string') {
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
                this.router.navigate(['/busqueda'], { queryParams: { texto: this.model } }));
            } else {
                if (this.model.CODIGO != null) {
                    this.seleccionarProducto(this.model);
                }
            }
        }

    }

    seleccionarProducto(producto: any) {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                if (producto.CATEGORIA && producto.CATEGORIA === 'CABLE' && producto.CODCOLOR !== '0') {
                    this.router.navigate(['/producto/' + producto.CODIGO.trim() + '/' + producto.CODCOLOR.trim()]);
                } else {
                    this.router.navigate(['/producto/' + producto.CODIGO.trim()]);
                }
            }
        );
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            map(term => term.length < 2 ? []
                : this.listadoProductosSearch.filter(v => v.NOMBRE.toLowerCase().trim().indexOf(term.toLowerCase()) > -1).slice(0, 10))
        )

    formatter = (x: {NOMBRE: string}) => x.NOMBRE.trim();

}
