<app-buscador></app-buscador>
<div class="container">
  <div class="row">
      <div class="col-md-12">
          <h1 style="text-align:center;">&iexcl;Est&aacute;s a punto de terminar tu compra!</h1>
          <h5 style="text-align:center;">Si a&uacute;n deseas agregar m&aacute;s productos, regresa al <b><a [routerLink]="['']">LISTADO DE PRODUCTOS</a></b></h5>
      </div>
  </div>
</div>

<div class="carrito">
  <div class="container">
      <div class="row">
        <div class="loading" *ngIf = "loading">
          <img src="assets/imgs/Loading.gif">
        </div>
          <div class="col-md-12">
              <form class="cart-form" action="" method="post">
                <table cellspacing="0">
                  <thead>
                    <tr>
                      <th class="product-remove">&nbsp;</th>
                      <th class="product-thumbnail">&nbsp;</th>
                      <th class="product-name">Producto</th>
                      <th class="product-price">Precio</th>
                      <th class="product-quantity">Cantidad</th>
                      <th class="product-subtotal">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr class="woocommerce-cart-form__cart-item cart_item" *ngFor="let producto of carrito?.productos" >
                        <td class="product-remove">
                            <a class="remove" (click)="eliminarProducto(producto)" ><i class="far fa-trash-alt"></i></a>
                        </td>
                        <td class="product-thumbnail">
                            <a [routerLink]="['/producto/' + producto.CODIGO.trim()]"><img width="200" src="assets/imgs/productos/{{producto?.CODIGO?.trim()}}.jpg" alt="NO DISPONIBLE"  onerror="this.src='https://placehold.it/200?text=NO DISPONIBLE'" /></a>
                        </td>
                        <td class="product-name">
                            <b>{{producto?.NOMBRE?.trim()?.toLowerCase()}}</b>
                        </td>
                        <td class="product-price">
                            <span>Q{{producto?.PRECIO | number:'.2'}}</span>
                        </td>
                        <td class="product-quantity">
                                <div class="quantity">
                                    <input id="cantidadProducto-{{producto.CODIGO.trim()}}" [(ngModel)]="producto.CANTIDAD" [ngModelOptions]="{standalone:true}" type="number" min="1" max="{{producto?.EXISTEN?.trim()}}" value="{{producto?.CANTIDAD}}" (change)="changeElemento(producto, $event)">
                                    </div>
                        </td>
                        <td class="product-subtotal">
                            <span id="subtotal-{{producto.CODIGO.trim()}}">Q{{(producto?.PRECIO * producto?.CANTIDAD) | number:'.2'}}</span>
                        </td>
                    </tr>

                    <tr>
                      <td colspan="6" class="actions">
                        <button type="button" (click)="actualizarCarrito()" class="btn-general btn-cart-total" name="update_cart" value="Actualizar carrito">Actualizar carrito</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
          </div>
      </div>
      
      <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
              <div class="cart_totals ">
                <h2><!-- Cart Totals --></h2>

                <table cellspacing="0" class="shop_table shop_table_responsive">
                  <tbody>
                      <!-- <tr class="cart-subtotal">
                        <th>Subtotal</th>
                        <td data-title="Subtotal"><span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">Q</span>{{carrito?.total | number:'.2'}}</span></td>
                      </tr> -->

                      <tr class="order-total">
                        <th>Total</th>
                        <td data-title="Total"><strong><span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">Q</span>{{carrito?.total | number:'.2'}}</span></strong> </td>
                      </tr>
                    </tbody>
                </table>

                <div class="wc-proceed-to-checkout">
                      <a class="btn-general btn-checkout" style="color: #ffffff;" (click)="guardarVenta()">pagar en caja</a>
                </div>

                <div class="wc-proceed-to-checkout">
                    <a class="btn-general btn-checkout" style="color: #ffffff;" (click)="pagoTarjeta()">pagar con tarjeta</a>
                </div>

              </div>
          </div>
      </div>
  </div>
</div>