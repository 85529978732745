import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-borrar',
    templateUrl: './borrar.component.html',
    styleUrls: ['./borrar.component.css']
})
export class BorrarComponent implements OnInit {

    constructor(private router: Router) { }

    ngOnInit() {
        const tiendaData = localStorage.getItem('currentStore');

        if (tiendaData != null) {
            localStorage.removeItem('currentStore');
            localStorage.removeItem('currentCarrito');
        }

        this.router.navigate(['']);
    }

}
