import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DatePipe, DecimalPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CompraService {

  constructor(private http: HttpClient, private dateFormat: DatePipe, private formatNumber: DecimalPipe) { }

  obtenerHttpOptions() {
    return {
        'headers': new HttpHeaders({
            'Content-Type': 'application/json'
            // 'Content-Type':  'text/plain'
        })
    };
  }

  testPrintEpson() {
    return this.http.get(`${environment.apiPrinterEpson}test`, this.obtenerHttpOptions());
  }

  printTicket(carrito: any, numeroContra: string, nombreTienda: string) {
    const ticket = {
      numeroContra: numeroContra,
      nombreTienda: nombreTienda,
      total: this.formatNumber.transform(carrito.total, '.2')
    };

    localStorage.removeItem('currentObjPrint');
    localStorage.setItem('currentObjPrint', JSON.stringify(ticket));

    return this.http.post(`${environment.apiPrinterEpson}ticket`, JSON.stringify(ticket), this.obtenerHttpOptions());
  }

  printVoucher(carrito: any, numeroContra: string, tienda: any, cardBrand: any, maskedCardNumber: any, authorizationNumber: any,
    referenceNumber: any, today: Date, printTags: any[]) {
    const products: any[] = [];

    for (const producto of carrito.productos) {
      const item = {
        nombre: producto.NOMBRE.trim().replace(/['"]+/g, ''),
        cantidad: Number(producto.CANTIDAD),
        precio: this.formatNumber.transform((producto.PRECIO * producto.CANTIDAD), '.2')
      };

      products.push(item);
    }

    const voucher = {
      numeroContra: numeroContra,
      nombreTienda: tienda.nombreTienda,
      total: this.formatNumber.transform(carrito.total, '.2'),
      terminalId: tienda.terminalId,
      cardBrand: cardBrand,
      productos: products,
      maskedCardNumber: maskedCardNumber,
      authorizationNumber: authorizationNumber,
      referenceNumber: referenceNumber,
      date: this.dateFormat.transform(today, 'dd/MM/yyyy'),
      time: this.dateFormat.transform(today, 'shortTime'),
      printTags: printTags
    };

    localStorage.removeItem('currentObjPrint');
    localStorage.setItem('currentObjPrint', JSON.stringify(voucher));

    return this.http.post(`${environment.apiPrinterEpson}voucher`, JSON.stringify(voucher), this.obtenerHttpOptions());
  }

  almacenarEncabezadoBorradorLocal(carrito: any, deposito: any) {
    const encabezado = {
      depocod: Number(deposito),
      fecha_oper: this.dateFormat.transform(new Date(), 'yyyy-MM-dd'),
      responsable: 'API',
      elaborado_por: 'PORTAL',
      costo: carrito.total,
      subtotal: carrito.total - ((carrito.total * 12) / 100),
      impuesto: ((carrito.total * 12) / 100),
      valor_total: carrito.total,
      total_lin: carrito.productos.length,
      estado: 'BORRADOR'
    };
    return this.http.post(`${environment.apiInternalUrl}encabezado/add`, JSON.stringify(encabezado), this.obtenerHttpOptions());
  }

  almacenarEncabezadoLocal(carrito: any, deposito: any, pagoTarjeta: boolean, id?: any,
    autNumber?: any, refNumber?: any, transactionId?: any) {
    const encabezado = {
      depocod: Number(deposito),
      tip_doc: 'F1',
      documento: '',
      fecha_emis: this.dateFormat.transform(new Date(), 'yyyy-MM-dd'),
      fecha_oper: this.dateFormat.transform(new Date(), 'yyyy-MM-dd'),
      fecha_vence: this.dateFormat.transform(new Date(), 'yyyy-MM-dd'),
      responsable: 'API',
      elaborado_por: 'PORTAL',
      clicod: '',
      documst: '',
      costo: carrito.total,
      subtotal: carrito.total - ((carrito.total * 12) / 100),
      impuesto: ((carrito.total * 12) / 100),
      valor_total: carrito.total,
      saldo: 0,
      fecan: this.dateFormat.transform(new Date(), 'yyyy-MM-dd'),
      descuento: 0,
      tipo_precio: '',
      moneda: '',
      total_lin: carrito.productos.length,
      nit: '',
      cobrador: 1,
      num_contra: '',
      vendedor2: '',
      tc: 1,
      estado: (pagoTarjeta) ? 'APROBADO_TARJETA' : 'APROBADO_CAJA',
      source: (pagoTarjeta) ? 'autNumber=' + autNumber : '',
      campaign: (pagoTarjeta) ? 'refNumber=' + refNumber : '',
      medium: (pagoTarjeta) ? 'transactionId=' + transactionId : ''
    };

    if (pagoTarjeta) {
      return this.http.put(`${environment.apiInternalUrl}encabezado/up/${id}`, JSON.stringify(encabezado), this.obtenerHttpOptions());
    } else {
      return this.http.post(`${environment.apiInternalUrl}encabezado/add`, JSON.stringify(encabezado), this.obtenerHttpOptions());
    }
  }

  enviarEncabezado(carrito: any, deposito: any, contador: any) {
    const encabezado = {
      depocod: Number(deposito),
      tip_doc: 'F1',
      documento: '',
      fecha_emis: this.dateFormat.transform(new Date(), 'dd/MM/yyyy'),
      fecha_oper: this.dateFormat.transform(new Date(), 'dd/MM/yyyy'),
      fecha_vence: this.dateFormat.transform(new Date(), 'dd/MM/yyyy'),
      responsable: 'API',
      elaborado_por: 'PORTAL',
      clicod: '',
      documst: '',
      costo: carrito.total,
      subtotal: carrito.total - ((carrito.total * 12) / 100),
      impuesto: ((carrito.total * 12) / 100),
      valor_total: carrito.total,
      saldo: 0,
      fecan: this.dateFormat.transform(new Date(), 'dd/MM/yyyy'),
      descuento: 0,
      tipo_precio: '',
      moneda: '',
      total_lin: carrito.productos.length,
      nit: '',
      cobrador: 1,
      num_contra: contador.toString(),
      vendedor2: '',
      tc: 1
    };
    return this.http.post(`${environment.apiUrl}Factura/facturacab`, JSON.stringify(encabezado), this.obtenerHttpOptions());
  }

  almacenarDetalleLocal(id: any, producto: any) {
    const descripcion = producto.NOMBRE.trim().replace(/['"]+/g, '');
    const detalle = {
      documlk: Number(id),
      invcod1: producto.CODIGO.trim(),
      depocod: Number(producto.DEPOSITO),
      ubicacion: '1',
      empq: 1,
      cantidad: Number(producto.CANTIDAD),
      costo_v: 1,
      precio_n: producto.PRECIO * 1,
      descri: descripcion,
      cod_color: (producto.CODCOLOR) ? Number(producto.CODCOLOR) : 0,
      invgrp: '1',
      tc: 1,
      beneficio: 1,
      fecha_oper: this.dateFormat.transform(new Date(), 'yyyy-MM-dd'),
      tip_doc: 'F1',
      costo_c: 0,
      precio_v: producto.PRECIO * 1,
      importe: (producto.PRECIO * producto.CANTIDAD),
      costo_p: 0
    };
    return this.http.post(`${environment.apiInternalUrl}detalle/add`, JSON.stringify(detalle), this.obtenerHttpOptions());
  }

  evniarDetalle(id: any, producto: any) {
    const descripcion = producto.NOMBRE.trim().replace(/['"]+/g, '');
    const detalle = {
      documlk: Number(id),
      invcod1: producto.CODIGO.trim(),
      depocod: Number(producto.DEPOSITO),
      ubicacion: '1',
      empq: 1,
      cantidad: Number(producto.CANTIDAD),
      costo_v: 1,
      precio_n: producto.PRECIO * 1,
      descri: descripcion,
      cod_color: (producto.CODCOLOR) ? Number(producto.CODCOLOR) : 0,
      invgrp: '1',
      tc: 1,
      beneficio: 1,
      fecha_oper: this.dateFormat.transform(new Date(), 'dd/MM/yyyy'),
      tip_doc: 'F1',
      costo_c: 0,
      precio_v: producto.PRECIO * 1,
      importe: (producto.PRECIO * producto.CANTIDAD),
      costo_p: 0
    };
    return this.http.post(`${environment.apiUrl}Factura/facturadet`, JSON.stringify(detalle), this.obtenerHttpOptions());
  }
}
