<app-buscador [totalCarrito]="totalCarrito"></app-buscador>
<div class="container">
  <div class="row">
      <div class="col-md-12" *ngIf="!staticAlertAddClosed">
          <div class="success-cart alert-cart">
              <p><i (click)="ocultarMensaje('Add')" class="fas fa-check-double"></i> El producto ha sido agregado con &eacute;xito a tu carrito de compras.</p>
          </div>
      </div>
      
      <div class="col-md-12" *ngIf="!staticAlertEmptyClosed">
          <div class="error-cart alert-cart">
              <p><i  class="fas fa-times"></i> El producto no se encuentra disponible en este momento, dir&iacute;gete al mostrador para ver las opciones.</p>
          </div>
      </div>
      
      <div class="col-md-12" *ngIf="!staticAlertMaxClosed">
        <div class="error-cart alert-cart">
            <p><i (click)="ocultarMensaje('Max')" class="fas fa-times"></i> La cantidad es mayor a la existencia.</p>
        </div>
      </div>

      <div class="col-md-12" *ngIf="!staticAlertExisteClosed">
        <div class="error-cart alert-cart">
            <p><i (click)="ocultarMensaje('Existe')" class="fas fa-times"></i> El producto ya existe en tu carrito, ve a tu carrito para editarlo!.</p>
        </div>
      </div>

      <div class="col-md-12" *ngIf="!staticAlertColorClosed">
        <div class="warning-cart alert-cart">
            <p><i (click)="ocultarMensaje('CableColor')" class="fas fa-warning"></i> Debe elegir el color del producto.</p>
        </div>
    </div>

  </div>
</div>

<div class="product-detail">
  <div class="container">
      <div class="row">
          <div class="col-md-5">
              <div class="product-image">
                  <img src="assets/imgs/productos/{{producto?.CODIGO?.trim()}}.jpg" alt="NO DISPONIBLE"  onerror="this.src='https://placehold.it/500x427?text=NO DISPONIBLE'"  />
              </div>
          </div>
          
          <div class="col-md-4">
              <h1 class="text-capitalize" *ngIf="!producto?.CATEGORIA || producto?.CATEGORIA !== 'CABLE'">{{producto?.NOMBRE?.trim()?.toLowerCase()}}</h1>
              <h1 class="text-capitalize" *ngIf="producto?.CATEGORIA && producto?.CATEGORIA == 'CABLE'">{{producto?.NOMBRE?.trim()?.toLowerCase()}} -{{producto?.CODCOLOR?.trim()}}-</h1>
              <p><b>C&oacute;digo:</b> {{producto?.CODIGO?.trim()}}</p>
              <!-- <h3 *ngIf="producto?.DESCRIPCION" >Descripci&oacute;n:</h3>
              <div class="product-text">
                  <p>{{producto?.DESCRIPCION}}</p>
                  <ul>
                      <li *ngFor="let detalle of producto?.DETALLES" >{{detalle.name}}</li>
                  </ul>
              </div> -->
              <p><b>Marca:</b> {{producto?.MARCA?.trim()}}</p>

              <!--EMPIEZA BLOQUE DE CABLES-->
              <div class="product-list-colors" *ngIf="producto?.CATEGORIA && producto?.CATEGORIA == 'CABLE'">
                <hr>
                <h4><b>Selecciona el color de tu preferencia:</b></h4>
                <select id="list-colors" name="colors" [(ngModel)]="producto.CODCOLOR" (change)="selectColor(producto, $event.target.value)">
                    <!--VALIDAR LOS VALORES QUE DEBEN APARECER SEGÚN EL EXCEL, NO TODOS LOS COLORES APLICAN PARA TODOS LOS PRODUCTOS-->
                    <option value="0" selected *ngIf="producto.CODCOLOR === '0'">Seleccionar</option>
                    <option value="2" *ngIf="['CHW17','CHW18','CHW19','CHW20','CHW21','CHW22','CHW23','ATW06','ATW07','ATW08'].indexOf(producto?.CODIGO?.trim()) !== -1" >Rojo</option>
                    <option value="3" *ngIf="['CHW17','CHW18','CHW19','CHW20','CHW21','CHW22','CHW23','ATW06','ATW07','ATW08'].indexOf(producto?.CODIGO?.trim()) !== -1">Amarillo</option>
                    <option value="4" *ngIf="['CHW17','CHW18','CHW19','CHW20','CHW21','CHW22','CHW23','CHW24','CHW25','CHW26','CHW27','CHW28','CHW29','CHW30','ATW06','ATW07','ATW08'].indexOf(producto?.CODIGO?.trim()) !== -1">Negro</option>
                    <option value="5" *ngIf="['CHW17','CHW18','CHW19','CHW20','CHW21','CHW22','CHW23','ATW06','ATW07','ATW08'].indexOf(producto?.CODIGO?.trim()) !== -1">Azul</option>
                    <option value="6" *ngIf="['CHW17','CHW18','CHW19','CHW20','CHW21','CHW22','CHW23','ATW06','ATW07','ATW08'].indexOf(producto?.CODIGO?.trim()) !== -1">Verde</option>
                    <option value="7" *ngIf="['CHW17','CHW18','CHW19','CHW20','CHW21','CHW22','CHW23','ATW06','ATW07','ATW08','CPT01','CPT02','CPT03','CPT04','CPT05'].indexOf(producto?.CODIGO?.trim()) !== -1">Blanco</option>
                    <option value="8" *ngIf="['CPT01','CPT02','CPT03','CPT04','CPT05'].indexOf(producto?.CODIGO?.trim()) !== -1">Gris</option>
                    <option value="9" *ngIf="['CPT01','CPT02','CPT03','CPT04','CPT05'].indexOf(producto?.CODIGO?.trim()) !== -1">Café</option>
                </select>
               </div>
               <!--TERMINA BLOQUE DE CABLES-->

              <!--EMPIEZA BLOQUE DE PINTURAS-->
              <div class="product-colors" *ngIf="producto?.SUB_CATEGORIA && producto?.SUB_CATEGORIA == 'PINTURA'">
                <hr>
                <h4><b>Selecciona el color de tu preferencia:</b></h4>
                <a class="product-detail-color" style="background:#ffffff;" (click)="seleccionarProductoWithCodigo('PI301')" >PI301</a>
                <a class="product-detail-color" style="background:#ffffff;" (click)="seleccionarProductoWithCodigo('PI303')" >PI303</a>
                <a class="product-detail-color" style="background:#99ffcc;" (click)="seleccionarProductoWithCodigo('PI304')" >PI304</a>
                <a class="product-detail-color" style="background:#ccffff;" (click)="seleccionarProductoWithCodigo('PI305')" >PI305</a>
                <a class="product-detail-color" style="background:#3399ff;" (click)="seleccionarProductoWithCodigo('PI306')" >PI306</a>
                <a class="product-detail-color" style="background:#ffff66;" (click)="seleccionarProductoWithCodigo('PI307')" >PI307</a>
                <a class="product-detail-color" style="background:#ffffcc;" (click)="seleccionarProductoWithCodigo('PI308')" >PI308</a>
                <a class="product-detail-color" style="background:#00cc99;" (click)="seleccionarProductoWithCodigo('PI309')" >PI309</a>
                <a class="product-detail-color" style="background:#99ffcc;" (click)="seleccionarProductoWithCodigo('PI310')" >PI310</a>
                <a class="product-detail-color" style="background:#ffcc66;" (click)="seleccionarProductoWithCodigo('PI311')" >PI311</a>
                <a class="product-detail-color" style="background:#ff9999;" (click)="seleccionarProductoWithCodigo('PI312')" >PI312</a>
                <a class="product-detail-color" style="background:#800000;" (click)="seleccionarProductoWithCodigo('PI313')" >PI313</a>
                <a class="product-detail-color" style="background:#ff9933;" (click)="seleccionarProductoWithCodigo('PI314')" >PI314</a>
                <a class="product-detail-color" style="background:#9999ff;" (click)="seleccionarProductoWithCodigo('PI316')" >PI316</a>
                <a class="product-detail-color" style="background:#000000;" (click)="seleccionarProductoWithCodigo('PI319')" >PI319</a>
                <a class="product-detail-color" style="background:#ffcc00;" (click)="seleccionarProductoWithCodigo('PI351')" >PI351</a>
                <a class="product-detail-color" style="background:#666633;" (click)="seleccionarProductoWithCodigo('PI352')" >PI352</a>
                <a class="product-detail-color" style="background:#000066;" (click)="seleccionarProductoWithCodigo('PI353')" >PI353</a>
                <a class="product-detail-color" style="background:#993300;" (click)="seleccionarProductoWithCodigo('PI354')" >PI354</a>
                <a class="product-detail-color" style="background:#ccff66;" (click)="seleccionarProductoWithCodigo('PI355')" >PI355</a>
                <a class="product-detail-color" style="background:#ffffff;" (click)="seleccionarProductoWithCodigo('PI356')" >PI356</a>
                <a class="product-detail-color" style="background:#999966;" (click)="seleccionarProductoWithCodigo('PI357')" >PI357</a>
                <a class="product-detail-color" style="background:#808080;" (click)="seleccionarProductoWithCodigo('PI359')" >PI359</a>
                <a class="product-detail-color" style="background:#66ffcc;" (click)="seleccionarProductoWithCodigo('PI364')" >PI364</a>
                <a class="product-detail-color" style="background:#ff6600;" (click)="seleccionarProductoWithCodigo('PI365')" >PI365</a>
                <a class="product-detail-color" style="background:#ff5050;" (click)="seleccionarProductoWithCodigo('PI366')" >PI366</a>
                <a class="product-detail-color" style="background:#00cc66;" (click)="seleccionarProductoWithCodigo('PI367')" >PI367</a>
                <a class="product-detail-color" style="background:#ff9966;" (click)="seleccionarProductoWithCodigo('PI368')" >PI368</a>
            </div>
            <!--TERMINA BLOQUE DE PINTURAS-->
          </div>
          
        <div class="col-md-3">
            <div class="product-cart">
                <p class="product-detail-price">Q{{producto?.PRECIO | number:'.2'}}</p>
                <h4 class="product-available">{{producto?.EXISTEN?.trim()}} disponibles</h4>
                <label>Cantidad</label>
                <input type="number" value="1" id="cantidadProducto" [(ngModel)]="cantidad" min="1" max="{{producto?.EXISTEN?.trim()}}" />
                <input class="btn-general" type="button" (click)="agregarCarrito(producto)" value="AGREGAR" />
                <img src="assets/imgs/tarjetas.png"  style="display: none;"/>
        
            </div>
        </div>

          
      </div>
  </div>
</div>
<div class="related-products products" *ngIf="listadoProducto?.length > 0">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <hr>
                <h2><b>PRODUCTOS RELACIONADOS</b></h2>
                <hr>
            </div>
        </div>

        <div class="row">
           
     
            <div class="col-md-3 col-sm-6" *ngFor="let productoRelacionado of listadoProducto">
                <a class="product" (click)="seleccionarProducto(productoRelacionado)">
                  <img src="assets/imgs/productos/{{productoRelacionado?.codigo?.trim()}}.jpg" alt="NO DISPONIBLE"  onerror="this.src='https://placehold.it/500x427?text=NO DISPONIBLE'"/>
                   
                    <hr>
                    <div class="product-list-detail">
                        <h4>ID: {{productoRelacionado?.codigo}}</h4>
                        <h3>{{productoRelacionado?.descripcion}}</h3>
                        <h5><span class="current-price">Q{{productoRelacionado.precio | number:'.2'}}</span></h5>
                    </div>
                </a>
            </div>
            
        </div>
    </div>
</div>