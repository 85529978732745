import { Component, OnInit } from '@angular/core';
import { ProductoService } from '../services/producto.service';
import { Producto } from '../models/producto.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.css']
})
export class ProductoComponent implements OnInit {

  listadoProductos: Producto[];
  nombreTienda: any = '[nombre_de_tienda]';

  constructor(public servicio: ProductoService, private router: Router) { }

  ngOnInit() {
    const tiendaData = localStorage.getItem('currentStore');

    if (tiendaData != null) {
      const tienda = JSON.parse(tiendaData);
      this.nombreTienda = tienda.nombreTienda;
      this.obtenerProducto(tienda.tienda_id);
    } else {
      this.router.navigate(['']);
    }
    
  }

  obtenerProducto(tienda_id: any) {
    this.servicio
      .listaProducto(tienda_id)
      .subscribe((data: any) => {
        if (data != null) {
          this.listadoProductos = data;
        } else {
          console.log('NO DATOS');
        }
      }, error => {
        console.error('ERROR EN SERVICIOS PRODUCTOS');
      });
  }

}
