import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusquedaService {

  constructor(private http: HttpClient) { }

    obtenerHttpOptions() {
        return {
            'headers': new HttpHeaders({
                'Content-Type': 'application/json'
                // 'Content-Type':  'text/plain'
            })
        };
    }

    buscarProductos(texto: any, id_tienda: any) {
      return this.http
            .get(`${environment.apiUrl}Productos/busquedaproductos/1/${id_tienda}/${texto}`,
                this.obtenerHttpOptions());
    }
}
