import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TiendaComponent } from './tienda/tienda.component';
import { ProductoComponent } from './producto/producto.component';
import { CarritoComponent } from './carrito/carrito.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { BuscadorComponent } from './buscador/buscador.component';
import { DetalleProductoComponent } from './detalle-producto/detalle-producto.component';
import { BorrarComponent } from './borrar/borrar.component';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { DatePipe, DecimalPipe } from '@angular/common';
import { OfertaComponent } from './oferta/oferta.component';
import { ResultadoBusquedaComponent } from './resultado-busqueda/resultado-busqueda.component';

@NgModule({
  declarations: [
    AppComponent,
    TiendaComponent,
    ProductoComponent,
    CarritoComponent,
    CheckoutComponent,
    BuscadorComponent,
    DetalleProductoComponent,
    BorrarComponent,
    OfertaComponent,
    ResultadoBusquedaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    AngularFontAwesomeModule
  ],
  providers: [
    DatePipe,
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
