import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CredomaticService {

  constructor(private http: HttpClient) { }

  obtenerHttpOptions() {
    return {
      'headers': new HttpHeaders({ 'Content-Type':  'application/json' })
    };
  }

  runTransaction(terminalId: any, invoice: any, totalAmount: any) {

    // terminalId = 'DEVAMC01'; // Comentar al momento de la prueba real

    const parametros = {
      parameters: `transactionType:SALE;terminalId:${terminalId};invoice:${invoice};totalAmount:${totalAmount};taxAmount:0;`
        + `tipAmount:0;keepCardDevice:OFF`
    };

    // return this.http.post(`${environment.apiInternalUrl}encabezado/pay/${invoice}`, JSON.stringify(parametros),
    // this.obtenerHttpOptions());

    return this.http.post(`${environment.apiCredomatic}runTransaction`, JSON.stringify(parametros), this.obtenerHttpOptions());

  }


}
