<header>
  <div class="container-fluid">
      <div class="row">
          <div class="col-md-3">
            <a  [routerLink]="['']">  
                <img src="assets/imgs/logo-celasa-guatemala.png" />
            </a>
          </div>
          <div class="col-md-6">
            <ng-template #rt let-r="result" let-t="term" >
                <img src="assets/imgs/productos/{{r.CODIGO.trim()}}.jpg" onerror="this.src='https://placehold.it/24x24?text=NO DISPONIBLE'" class="mr-1" style="width: 24px">
                <ngb-highlight [result]="r.NOMBRE.trim()" [term]="t" (click)="seleccionarProducto(r)" ></ngb-highlight>
              </ng-template>  
            <input placeholder="&iquest;Buscas alg&uacute;n producto en particular?.........." id="typeahead-basic" 
              type="text" class="form-control" (keyup.enter)="onKey($event)" [(ngModel)]="model" [ngbTypeahead]="search" [resultTemplate]="rt" [inputFormatter]="formatter"/>
          </div>
          <div class="col-md-3">
            <a class="btn-general btn-cart"  [routerLink]="['/carrito']"><i class="fas fa-shopping-cart"></i> Carrito <span class="cart-total">{{totalCarrito}}</span></a>
            <a class="btn-general btn-cart" [routerLink]="['/ofertas']" style="margin-right: 20px;"><i class="fas fa-grin-stars"></i> Ver Ofertas</a>  
          </div>
      </div>
  </div>
</header>