import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BusquedaService } from '../services/busqueda.service';

@Component({
  selector: 'app-resultado-busqueda',
  templateUrl: './resultado-busqueda.component.html',
  styleUrls: ['./resultado-busqueda.component.css']
})
export class ResultadoBusquedaComponent implements OnInit {

  texto: string;
  listadoProductosSearch: any;

  constructor(public servicio: BusquedaService,private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    const tiendaData = localStorage.getItem('currentStore');

    if (tiendaData != null) {
        const tienda = JSON.parse(tiendaData);
        this.route.queryParamMap.subscribe(params => {
            this.texto = params.get('texto');
            this.obtenerProductos(tienda.tienda_id);
        });
    } else {
        this.router.navigate(['']);
    }

  }

  obtenerProductos(tienda_id: any) {
    this.servicio
            .buscarProductos(this.texto.toUpperCase(), tienda_id)
            .subscribe((data: any) => {
                console.log(data);
                if (data != null) {
                    this.listadoProductosSearch = data;
                } else {
                    console.log('NO DATOS');
                }
            }, error => {
                console.error('ERROR EN SERVICIOS BUSQUEDA');
            });
  }

}
