import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompraService } from '../services/compra.service';
import swal from 'sweetalert';
import { DecimalPipe } from '@angular/common';
import { CredomaticService } from '../services/credomatic.service';

@Component({
    selector: 'app-carrito',
    templateUrl: './carrito.component.html',
    styleUrls: ['./carrito.component.css']
})
export class CarritoComponent implements OnInit {

    public carrito: any;
    public nombre: any;
    idFactura: any;
    loading: boolean;
    numeroContra: any;

    public isPagoTarjeta: boolean;
    printTags: any[];
    cardBrand: any;
    maskedCardNumber: any;
    authorizationNumber: any;
    referenceNumber: any;
    today: Date;
    isPrinterConnected: boolean;

    constructor(private router: Router, public servicio: CompraService, private formatNumber: DecimalPipe,
        private credomatic: CredomaticService) { }

    ngOnInit() {
        const carritoData = localStorage.getItem('currentCarrito');

        if (carritoData != null) {
            const carrito = JSON.parse(carritoData);
            this.carrito = carrito;
        } else {
            this.router.navigate(['']);
        }

        const nombreTienda = localStorage.getItem('currentStore');
        if (nombreTienda != null) {
            const nombre = JSON.parse(nombreTienda);
            this.nombre = nombre;
        } else {
            this.router.navigate(['']);
        }

    }

    eliminarProducto(producto: any) {
        const index = this.carrito.productos.findIndex(product => product.CODIGO.trim() === producto.CODIGO.trim());
        this.carrito.productos.splice(index, 1);
        this.carrito.total -= (producto.PRECIO * producto.CANTIDAD);
        localStorage.setItem('currentCarrito', JSON.stringify(this.carrito));
    }

    changeElemento(producto: any, event: any) {
        const cantidad = event.target.value;
        const precio = producto.PRECIO;
        document.getElementById('subtotal-' + producto.CODIGO.trim()).
            innerHTML = 'Q' + this.formatNumber.transform(precio * cantidad, '.2');
    }

    actualizarCarrito() {
        if (this.carrito.productos.length < 1) {
            this.loading = false;
            this.showError('Debe tener un producto agregado!');
            return false;
        }

        this.carrito.total = 0;
        for (const producto of this.carrito.productos) {
            this.carrito.total += (producto.PRECIO * producto.CANTIDAD);
        }
        localStorage.setItem('currentCarrito', JSON.stringify(this.carrito));
        swal({
            title: 'Actualizado!',
            text: '',
            icon: 'success',
          });
    }

    async checkprinter () {
        this.isPrinterConnected = false;
        return await this.servicio.testPrintEpson().toPromise();
    }

    async pagoTarjeta() {

        await this.checkprinter().then((resp: any) => {
            if  (resp != null && resp.status != null && resp.status === 1) {
                this.isPrinterConnected = true;
                console.log('Impresora conectada');
            } else {
                console.error('Problemas con la impresora ', resp);
                this.showError('Debe concetar la impresora, o verifique que celasa-printer.exe <IP-PRINTER> se encuentre corriendo');
            }
        }).catch((error) => {
            console.error('ERROR CON LA IMPRESORA ', error);
            this.showError('Debe concetar la impresora, o verifique que celasa-printer.exe <IP-PRINTER> se encuentre corriendo');
        });

        if (!this.isPrinterConnected) {
            return false;
        }

        this.isPagoTarjeta = true;
        this.loading = true;
        const carritoData = localStorage.getItem('currentCarrito');
        const tiendaData = localStorage.getItem('currentStore');

        if (carritoData != null) {
            const carrito = JSON.parse(carritoData);
            const tienda = JSON.parse(tiendaData);

            if (carrito.productos.length < 1) {
                this.loading = false;
                this.showError('Debe tener un producto agregado!');
                return false;
            }


            this.servicio.almacenarEncabezadoBorradorLocal(carrito, tienda.tienda_id)
                .subscribe((respueta: any) => {
                    if (respueta != null && respueta.id != null) {
                        this.numeroContra = respueta.id;

                        this.credomatic.runTransaction(tienda.terminalId, this.numeroContra, carrito.total)
                            .subscribe((data: any) => {
                                if (data != null) {
                                    console.log(data);
                                    let strResult = data.runTransactionResult.toString().trim().replace(/(?:\r\n|\r|\n|\t)/g, '');
                                    strResult = strResult.replace('"{', '{');
                                    strResult = strResult.replace('}"', '}');
                                    console.log(strResult);
                                    const credomaticResponse = JSON.parse(strResult);
                                    // const credomaticResponse = JSON.parse(data);
                                    console.log(credomaticResponse);
                                    if (credomaticResponse.responseCode === '00') {

                                        this.cardBrand = credomaticResponse.cardBrand;
                                        this.maskedCardNumber = credomaticResponse.maskedCardNumber;
                                        this.authorizationNumber = credomaticResponse.authorizationNumber;
                                        this.referenceNumber = credomaticResponse.referenceNumber.toString().trim();
                                        this.today = new Date();
                                        this.printTags = credomaticResponse.printTags;
                                        const transactionId = credomaticResponse.transactionId;

                                        console.log(this.cardBrand);
                                        console.log(this.maskedCardNumber);

                                        this.guardarVenta(true, this.numeroContra, this.authorizationNumber,
                                            this.referenceNumber, transactionId);
                                        // this.showAlertSale();
                                    } else if (credomaticResponse.responseCode === '05') {
                                        this.showErrorAndConntinue(`Tarjeta rechazada!`);
                                    } else {
                                        this.showErrorAndConntinue(`Problemas con Credomatic, comunicate con el encargado de tienda!`
                                        + `\n ${credomaticResponse.responseCode} : ${credomaticResponse.responseCodeDescription}`
                                        + `\n ${credomaticResponse.errorDescription}`);
                                    }
                                } else {
                                    console.error('NO DATOS DEL SERVICIO DE CREDOMATIC');
                                    this.showErrorAndConntinue('Problemas con Credomatic, comunicate con el encargado de tienda!');
                                }
                                // this.loading = false;
                            }, error => {
                                console.error('ERROR EN SERVICIOS CREDOMATIC');
                                console.error(error);
                                if (error.status === 0) {
                                    console.error('Error comunicacion asegurse que los servicios de Credomatic y Nginx esten arriba!');
                                    this.showErrorAndConntinue('Problemas con la comunicación de Credomatic, ' +
                                        'comunicate con el encargado de tienda!');
                                } else {
                                    this.showErrorAndConntinue('Problemas con Credomatic, comunicate con el encargado de tienda!');
                                }

                            });

                    } else {
                        console.error('NO SE ALMACENA ENCABEZADO', respueta);
                    }
                }, error => {
                    console.error('ERROR EN SERVICIOS LOCAL ENCABEZADO');
                });

        }

    }

    async guardarVenta(pagoTarjeta?: boolean, encabezadoId?: any, autNumber?: any, refNumber?: any, transactionId?: any) {

        await this.checkprinter().then((resp: any) => {
            if  (resp != null && resp.status != null && resp.status === 1) {
                this.isPrinterConnected = true;
                console.log('Impresora conectada');
            } else {
                console.error('Problemas con la impresora ', resp);
                this.showError('Debe concetar la impresora, o verifique que celasa-printer.exe <IP-PRINTER> se encuentre corriendo');
            }
        }).catch((error) => {
            console.error('ERROR CON LA IMPRESORA ', error);
            this.showError('Debe concetar la impresora, o verifique que celasa-printer.exe <IP-PRINTER> se encuentre corriendo');
        });

        if (!this.isPrinterConnected) {
            return false;
        }

        this.isPagoTarjeta = pagoTarjeta;
        this.loading = true;
        const carritoData = localStorage.getItem('currentCarrito');
        const tiendaData = localStorage.getItem('currentStore');

        if (carritoData != null) {
            const carrito = JSON.parse(carritoData);
            const tienda = JSON.parse(tiendaData);

            if (carrito.productos.length < 1) {
                this.loading = false;
                this.showError('Debe tener un producto agregado!');
                return false;
            }

            this.servicio.almacenarEncabezadoLocal(carrito, tienda.tienda_id, pagoTarjeta, encabezadoId,
                autNumber, refNumber, transactionId)
                .subscribe((respueta: any) => {
                    if (respueta != null) {
                        this.numeroContra = (pagoTarjeta) ? encabezadoId : respueta.id;

                        this.servicio
                            .enviarEncabezado(carrito, tienda.tienda_id, this.numeroContra)
                            .subscribe((data: any) => {
                                if (data != null) {
                                    if (data.id != null) {
                                        this.idFactura = data.id;
                                        let pase = true;
                                        for (const product of carrito.productos) {
                                            this.servicio.almacenarDetalleLocal(this.numeroContra, product)
                                                .subscribe((respuetaDetalle: any) => {
                                                    if (respuetaDetalle != null && respuetaDetalle.id != null) {
                                                        this.servicio
                                                            .evniarDetalle(this.idFactura, product)
                                                            .subscribe((result: any) => {
                                                                if (result != null) {
                                                                    for (const resultado of result) {
                                                                        if (resultado.status !== 1) {
                                                                            pase = false;
                                                                            console.log('Error al intentar almacenar el detalle');
                                                                            console.error(result);
                                                                        }
                                                                    }
                                                                } else {
                                                                    console.log('NO DATOS');
                                                                    pase = false;
                                                                }
                                                            }, error => {
                                                                pase = false;
                                                                console.error('ERROR EN SERVICIOS COMPRA DETALLE');
                                                            });
                                                    } else {
                                                        pase = false;
                                                        console.error('NO SE ALMACENA DETALLE', respuetaDetalle);
                                                    }
                                                }, error => {
                                                    pase = false;
                                                    console.error('ERROR EN SERVICIOS LOCAL DETALLE');
                                                });
                                        }

                                        if (pase) {
                                            this.loading = false;
                                            this.showAlertSale();
                                        } else {
                                            this.loading = false;
                                            this.showError('Ocurrio un error con tu compra, intenta mas tarde!');
                                            return false;
                                        }
                                    }
                                } else {
                                    console.log('NO DATOS');
                                }
                            }, error => {
                                console.error('ERROR EN SERVICIOS COMPRA');
                            });

                    } else {
                        console.error('NO SE ALMACENA ENCABEZADO', respueta);
                    }
                }, error => {
                    console.error('ERROR EN SERVICIOS LOCAL ENCABEZADO');
                });
        }
    }

    showError(mensaje: any)  {
        this.loading = false;
        swal({
            title: 'Oopss!',
            text: mensaje,
            icon: 'error',
            closeOnClickOutside: false,
            closeOnEsc: false
        });
    }

    showErrorAndConntinue(mensaje: any)  {
        this.loading = false;
        swal({
            title: 'Oopss!',
            text: mensaje,
            icon: 'error',
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: {
                tryagain: {
                  text: 'Intentar de nuevo',
                  value: 'tryagain',
                },
                cancel: {
                    text: 'Cerrar',
                    visible: true,
                    closeModal: true
                }
            }
        }).then((value) => {
            switch (value) {

              case 'tryagain':
                this.pagoTarjeta();
                break;

              case 'othermethod':
                this.guardarVenta();
                break;

              default:
                swal.close();
            }
        });
    }

    async print () {
        if (this.isPagoTarjeta) {
            return await this.servicio.printVoucher(this.carrito, this.numeroContra, this.nombre, this.cardBrand, this.maskedCardNumber,
                this.authorizationNumber, this.referenceNumber, this.today, this.printTags).toPromise();
        } else {
            return await this.servicio.printTicket(this.carrito, this.numeroContra, this.nombre.nombreTienda).toPromise();
        }
    }

    async showAlertSale() {
        swal({
            title: this.numeroContra,
            text: 'Gracias por realizar tu compra en: ' + '\n' + this.nombre.nombreTienda
            + '\n\n' + 'El valor de compra es de: Q' + this.formatNumber.transform(this.carrito.total, '.2'),
            icon: 'success',
        }).then((value) => {

            this.print().then((resp: any) => {
                if  (resp != null && resp.status != null && resp.status === 1) {
                    console.log('Ticket Impreso', resp);
                } else {
                    console.error('Problemas con la impresora ', resp);
                    this.showError('Debe concetar la impresora, o verifique que celasa-printer.exe <IP-PRINTER> se encuentre corriendo');
                }
            }).catch((error) => {
                console.error('ERROR CON LA IMPRESORA');
                this.showError('Debe concetar la impresora, o verifique que celasa-printer.exe <IP-PRINTER> se encuentre corriendo');
            });

            localStorage.removeItem('currentCarrito');
            const carrito = {
                productos: [],
                orden: {
                    nit: '',
                    nombre: '',
                    apellido: '',
                    direccion: ''
                },
                total: 0
            };

            localStorage.setItem('currentCarrito', JSON.stringify(carrito));
            this.router.navigate(['']);
        });
    }

}
