import { Component, OnInit } from '@angular/core';
import { TiendaService } from '../services/tienda.service';
import { Router } from '@angular/router';
import { Tienda } from '../models/tienda.model';
import { Producto } from '../models/producto.model';

@Component({
    selector: 'app-tienda',
    templateUrl: './tienda.component.html',
    styleUrls: ['./tienda.component.css']
})
export class TiendaComponent implements OnInit {

    listadoTiendas: Tienda[];

    constructor(public servicio: TiendaService, private router: Router) {

    }

    ngOnInit() {
        const tiendaData = localStorage.getItem('currentStore');

        if (tiendaData != null) {
            const tienda = JSON.parse(tiendaData);
            this.router.navigate(['/' + tienda.tienda_id]);
        } else {
            this.obtenerTiendas();
        }

    }

    seleccionarTienda(tienda_id: any, nombreTienda: any, terminalId: any) {
        const tienda = {
            tienda_id: tienda_id,
            nombreTienda: nombreTienda,
            terminalId: terminalId
        };

        const carrito = {
            productos: [],
            orden: {
                nit: '',
                nombre: '',
                apellido: '',
                direccion: ''
            },
            total: 0
        }

        localStorage.setItem('currentStore', JSON.stringify(tienda));
        localStorage.setItem('currentCarrito', JSON.stringify(carrito));

        this.router.navigate(['/' + tienda.tienda_id]);
    }

    obtenerTiendas() {
        this.servicio
            .listaTiendas()
            .subscribe((data: any) => {
                if (data != null) {
                    this.listadoTiendas = data;
                } else {
                    console.log('NO DATOS')
                }
            }, error => {
                console.error('ERROR EN SERVICIOS TIENDAS');
            })
    }

}
