import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProductoService {

    constructor(private http: HttpClient) { }

    obtenerHttpOptions() {
        return {
            'headers': new HttpHeaders({
                'Content-Type': 'application/json'
                // 'Content-Type':  'text/plain'
            })
        };
    }

    listaProducto(id_tienda: any) {
        return this.http
            .get(`${environment.apiUrl}Productos/productos/1/${id_tienda}/`,
                this.obtenerHttpOptions());
    }

    obtenerProducto(id_tienda: any, id_producto: any, color: any) {
        if (color !== null) {
            return this.http
            .get(`${environment.apiUrl}Productos/productos/1/${id_tienda}/${id_producto}/${color}`,
                this.obtenerHttpOptions());
        }
        return this.http
            .get(`${environment.apiUrl}Productos/productos/1/${id_tienda}/${id_producto}`,
                this.obtenerHttpOptions());
    }

    listaProductoRelacionado(id_tienda: any, id_producto: any) {
        return this.http
            .get(`${environment.apiUrl}Productos/productosdepohomologa/1/${id_tienda}/${id_producto}`,
                this.obtenerHttpOptions());
    }
}
