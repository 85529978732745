import { Component, OnInit, Input } from '@angular/core';
import { Producto } from '../models/producto.model';
import { ProductoService } from '../services/producto.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
    selector: 'app-detalle-producto',
    templateUrl: './detalle-producto.component.html',
    styleUrls: ['./detalle-producto.component.css']
})
export class DetalleProductoComponent implements OnInit {

    producto: Producto;
    public cantidad: any = 1;
    totalCarrito: any = 0;
    staticAlertAddClosed = true;
    staticAlertEmptyClosed = true;
    staticAlertMaxClosed = true;
    staticAlertExisteClosed = true;
    staticAlertColorClosed = true;
    listadoProducto: Producto[];

    constructor(public servicio: ProductoService, private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        setTimeout(() => this.ocultarTodos(), 10000);

        const tiendaData = localStorage.getItem('currentStore');

        if (tiendaData != null) {
            const tienda = JSON.parse(tiendaData);
            const producto_codigo = this.route.snapshot.paramMap.get('id');
            const color_codigo = this.route.snapshot.paramMap.get('color');
            this.obtenerProducto(tienda.tienda_id, producto_codigo, color_codigo);
            this.obtenerProductoRelacionado(tienda.tienda_id, producto_codigo);

            const carritoData = localStorage.getItem('currentCarrito');

            if (carritoData != null) {
                const carrito = JSON.parse(carritoData);
                this.totalCarrito = carrito.productos.length;
            } else {
                this.totalCarrito = 0;
            }

        } else {
            this.router.navigate(['']);
        }
    }

    ocultarTodos() {
        this.ocultarMensaje('Add');
        this.ocultarMensaje('Max');
        this.ocultarMensaje('Existe');
    }

    ocultarMensaje(staticAlert: string) {
        if (staticAlert === 'Add') {
            this.staticAlertAddClosed = true;
        } else if (staticAlert === 'Max') {
            this.staticAlertMaxClosed = true;
        } else if (staticAlert === 'Existe') {
            this.staticAlertExisteClosed = true;
        } else if (staticAlert === 'CableColor') {
            this.staticAlertColorClosed = true;
        }
    }

    obtenerProducto(tienda_id: any, producto_codigo: any, color_codigo: any) {
        this.servicio
            .obtenerProducto(tienda_id, producto_codigo, color_codigo)
            .subscribe((data: any) => {
                if (data != null) {
                    this.producto = data[0];
                    if (Number(this.producto.EXISTEN) <= 0 && this.producto.CATEGORIA !== 'CABLE') {
                        this.staticAlertEmptyClosed = false;
                    } else if (this.producto.CATEGORIA === 'CABLE' && this.producto.CODCOLOR === '0') {
                        this.staticAlertColorClosed = false;
                    }
                } else {
                    console.log('NO DATOS');
                }
            }, error => {
                console.error('ERROR EN SERVICIOS PRODUCTOS');
            });
    }

    obtenerProductoRelacionado(tienda_id: any, producto_codigo: any) {
        this.servicio
            .listaProductoRelacionado(tienda_id, producto_codigo)
            .subscribe((data: any) => {
                if (data != null) {
                    this.listadoProducto = data;
                } else {
                    console.log('NO DATOS');
                }
            }, error => {
                console.error('ERROR EN SERVICIOS PRODUCTOS');
            });
    }

    seleccionarProducto(producto: any) {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
        this.router.navigate(['/producto/' + producto.codigo.trim()]));
    }

    selectColor(producto: any, color: any) {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
        this.router.navigate(['/producto/' + producto.CODIGO.trim() + '/' + producto.CODCOLOR.trim()]));
    }

    seleccionarProductoWithCodigo(codigoProducto: any) {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
        this.router.navigate(['/producto/' + codigoProducto.trim()]));
    }

    agregarCarrito(producto: any) {
        const carritoData = localStorage.getItem('currentCarrito');

        if (carritoData != null) {
            const carrito = JSON.parse(carritoData);
            if (Number(producto.EXISTEN) < this.cantidad) {
                this.staticAlertMaxClosed = false;
                this.staticAlertAddClosed = true;
                return false;
            }

            let existe = false;
            for (const product of carrito.productos) {
                if (product.CODIGO.trim() === producto.CODIGO.trim()) {
                    existe = true;
                    this.staticAlertExisteClosed = false;
                }
            }

            if (existe) {
                return false;
            }

            producto.CANTIDAD = this.cantidad;
            if (producto.CATEGORIA === 'CABLE' && producto.CODCOLOR !== '0') {
                producto.NOMBRE += ` -${producto.CODCOLOR.trim()}-`;
            }
            carrito.productos.push(producto);
            carrito.total += (producto.PRECIO * producto.CANTIDAD);
            this.totalCarrito = carrito.productos.length;
            this.staticAlertAddClosed = false;
            this.staticAlertMaxClosed = true;
            localStorage.setItem('currentCarrito', JSON.stringify(carrito));
        }
    }

}
