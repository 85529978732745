<app-buscador></app-buscador>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h1 style="text-align:center;">&iexcl;Listado General de Productos!</h1>
            <h5 style="text-align:center;">Gracias por visitar nuestra tienda <b>{{nombreTienda}}</b></h5>
        </div>
    </div>
</div>

<div class="products">
    <div class="container">
        <div class="row">

            <div class="col-md-3 col-sm-6" *ngFor="let producto of listadoProductos">
                <a class="product" *ngIf="producto.CATEGORIA && producto.CATEGORIA === 'CABLE' && producto.CODCOLOR !== '0'" [routerLink]="['/producto/' + producto.CODIGO.trim() + '/' + producto.CODCOLOR.trim()]">
                    <img class="imgProducto" src="assets/imgs/productos/{{producto.CODIGO.trim()}}.jpg" alt="NO DISPONIBLE"  onerror="this.src='https://placehold.it/248x211?text=NO DISPONIBLE'"  />
                    <hr>
                    <div class="product-list-detail">
                        <h4>ID: {{producto.CODIGO.trim()}}</h4>
                        <h3 class="text-capitalize">{{producto.NOMBRE.trim().toLowerCase()}}</h3>
                        <h5><span class="current-price">Q{{producto.PRECIO | number:'.2'}}</span></h5>
                    </div>
                </a>
                <a class="product" *ngIf="!producto.CATEGORIA || producto.CATEGORIA !== 'CABLE'" [routerLink]="['/producto/' + producto.CODIGO.trim()]">
                    <img class="imgProducto" src="assets/imgs/productos/{{producto.CODIGO.trim()}}.jpg" alt="NO DISPONIBLE"  onerror="this.src='https://placehold.it/248x211?text=NO DISPONIBLE'"  />
                    <hr>
                    <div class="product-list-detail">
                        <h4>ID: {{producto.CODIGO.trim()}}</h4>
                        <h3 class="text-capitalize">{{producto.NOMBRE.trim().toLowerCase()}}</h3>
                        <h5><span class="current-price">Q{{producto.PRECIO | number:'.2'}}</span></h5>
                    </div>
                </a>
            </div>

        </div>


        <div class="row" style="display: none">
            <div class="col-md-12">
                <a class="btn-general">VER M&Aacute;S PRODUCTOS</a>
                <a class="btn-general loading"><i class="fas fa-spinner"></i> CARGANDO PRODUCTOS</a>
            </div>
        </div>
    </div>
</div>