<app-buscador></app-buscador>    
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1 style="text-align:center;">¡OFERTAS INCREÍBLES!</h1>
                    <h5 style="text-align:center;">Haz clic sobre el código de producto de tu interés</h5>
                    <iframe src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=fxcfd0fnv" width="1000" height="1000" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen></iframe>
                </div>
            </div>
        </div>
