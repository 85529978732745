<app-buscador></app-buscador>
<div class="container">
  <div class="row">
      <div class="col-md-12">
          <h1 style="text-align:center;">&iexcl;Completa la informaci&oacute;n de facturaci&oacute;n!</h1>
          <h5 style="text-align:center;">Una vez finalizada tu compra, dir&iacute;gete a realizar tu pago en ventanilla</h5>
      </div>
  </div>
</div>

<div class="checkout">
  <div class="container">
      <form class="cart-form" action="#" method="post">
          <div class="row">
              <div class="col-md-6">
                  <h2>COMPLETA LOS DATOS A CONTINUACI&Oacute;N</h2>
                  <p>
                      <label>NIT</label><br/>
                      <input type="text" placeholder="INGRESA TU NIT" />
                  </p>
                  
                  <p>
                      <label>NOMBRE</label><br/>
                      <input type="text" placeholder="INGRESA TU NOMBRE" />
                  </p>
                  
                  <p>
                      <label>APELLIDO</label><br/>
                      <input type="text" placeholder="INGRESA TU APELLIDO" />
                  </p>
                  
                  <p>
                      <label>DIRECCI&Oacute;N</label><br/>
                      <input type="text" placeholder="INGRESA TU DIRECCI&Oacute;N" />
                  </p>
              </div>
              <div class="col-md-6">
                    <table cellspacing="0">
                      <thead>
                        <tr>
                          <th class="product-name">Producto</th>
                          <th class="product-price">Precio</th>
                          <th class="product-quantity">Cantidad</th>
                          <th class="product-subtotal">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr class="woocommerce-cart-form__cart-item cart_item" *ngFor="let producto of carrito?.productos" >
                          <td class="product-name">
                            <b>VENTILADOR DE PISO 5 ASPAS 3 VELOCIDADES 'LUXLITE'</b>
                          </td>
                          <td class="product-price">
                            <span>Q305.27</span>
                          </td>
                          <td class="product-quantity">
                                  <div class="quantity">
                                      <input type="number" min="1" max="7" value="1">
                                    </div>
                          </td>
                          <td class="product-subtotal">
                            <span>305.27</span>
                          </td>
                        </tr>
                        
                          
                      </tbody>
                    </table>
              </div>
          </div>
          
          <div class="row">
              <div class="col-md-12">
                  <button type="submit" class="btn-general btn-cart-total" disabled="disabled" name="send_order" value="COMPLETAR PEDIDO">COMPLETAR PEDIDO</button>
              </div>
          </div>
      </form>
  </div>
</div>
