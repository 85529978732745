<header>
  <div class="container-fluid">
      <div class="row">
          <div class="col-md-12">
            <a  [routerLink]="['']">  
                <img style="margin:0 auto; display:block;" src="assets/imgs/logo-celasa-guatemala.png" />
            </a>
          </div>
      </div>
  </div>
</header>
<div class="container">
  <div class="row">
      <div class="col-md-12">
          <h1 style="text-align:center;">&iexcl;Selecciona la tienda en la que te encuentras!</h1>
          <h5 style="text-align:center;">Esta pantalla deber&aacute; iniciarse cada ma&ntilde;ana al encender las pantallas.....</h5>
      </div>
  </div>
</div>

<div class="stores">
  <div class="container">
      <div class="row">

          <div class="col-md-3 col-sm-6" *ngFor="let tienda of listadoTiendas" >
              <a class="store" (click)="seleccionarTienda(tienda.ID.trim(), tienda.NOMBRE.trim(), tienda.TERMINAL_ID.trim())">
                  <img src="assets/imgs/tiendas/{{tienda.ID.trim()}}.jpg" alt="NO DISPONIBLE"  onerror="this.src='https://placehold.it/206x110?text=NO DISPONIBLE'"  />
                  <h4>ID: {{tienda.ID.trim()}}</h4>
                  <h4 class="text-capitalize">{{tienda.NOMBRE.trim().toLowerCase()}}</h4>
              </a>
          </div>
          
      </div>
  </div>
</div>